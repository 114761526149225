import { profile, query, visualization } from "@fscrypto/domain";
import { QueryRunResult } from "@fscrypto/domain/query-run";
import { optionsBuilder } from "@fscrypto/viz-2";
import { VisualizationRenderer } from "@fscrypto/viz-2";
import deepEqual from "fast-deep-equal";
import { memo } from "react";
import { useTableState } from "~/features/studio-2/query/state/query-table-state";
import VisualizationPanelContainer from "./visualization-panel-container";
import { VisLoading } from "./vizLoading";

interface V3VisualizationPanelProps {
  owner: profile.Profile;
  visualization: visualization.v3.Visualization;
  isEditable: boolean;
  query: query.Query;
  queryRunResult: QueryRunResult;
  isFetching: boolean;
}

export const V3VisualizationPanel = ({
  visualization,
  query,
  owner,
  isEditable,
  queryRunResult,
  isFetching,
}: V3VisualizationPanelProps) => {
  const [options] = optionsBuilder(visualization.config, queryRunResult);
  return (
    <VisualizationPanelContainer
      owner={owner}
      title={options.title?.text ?? ""}
      id={visualization.id}
      isEditable={isEditable}
      query={query}
      type={"bar"}
      version="3"
    >
      {isFetching || !visualization ? (
        <VisLoading />
      ) : (
        <MemoizedV3Visualization
          inputs={visualization.config.inputs}
          visualizationId={visualization.id}
          options={options as visualization.v3.Options}
          startedAt={queryRunResult.startedAt}
          endedAt={queryRunResult.endedAt}
        />
      )}
    </VisualizationPanelContainer>
  );
};

const V3Visualization = ({
  visualizationId,
  options,
  inputs,
  startedAt,
  endedAt,
}: {
  options: visualization.v3.Options;
  visualizationId: string;
  inputs: visualization.v3.Inputs;
  startedAt: Date | string | undefined | null;
  endedAt: Date | undefined | string | null;
}) => {
  const tableProps = useTableState(`query-${visualizationId}`);
  return (
    <VisualizationRenderer
      input={inputs}
      options={{ ...options, title: { text: undefined } } as visualization.v3.Options}
      immutable={false}
      tableProps={{ ...tableProps, tableId: `query-${visualizationId}` }}
      startedAt={startedAt}
      endedAt={endedAt}
    />
  );
};

const MemoizedV3Visualization = memo(V3Visualization, (a, b) => {
  return deepEqual(a, b);
});
